@import "/base.scss";
@import "/heartbeat.scss";
@import "./variables";

* {
  outline: none;
}

#container {
  position: relative;
}

.sivo {
  color: #ccc;
}

.ladno {
  color: rgb(178, 212, 243);
}

p {
  margin: 0;
}
hr {
  margin: 20px;
  opacity: 0;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bum {
  animation: 0.8s infinite beatHeart;
  display: inline-block;
}

.glow {
  transition: 0.3s all;
  font-size: 26px;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #e60073, 0 0 4px #e60073,
    0 0 5px #e60073, 0 0 6px #e60073, 0 0 7px #e60073;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.overlay button {
  color: white;
  font-family: "Bad Script", cursive;
  text-transform: uppercase;
  border: 2px solid $color;
  border-radius: 4px;
  width: 200px;
  background: transparent;
  padding: 20px;
  transition: 0.5s all;
  text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #e60073, 0 0 4px #e60073,
    0 0 5px #e60073, 0 0 6px #e60073, 0 0 7px #e60073;
}

.overlay button:hover {
  transform: scale(1.1);
}

.overlay button:active {
  transform: scale(0.9);
}
