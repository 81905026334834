@import "./variables";
$colorHi: lighten(
  saturate(
    $color: $color,
    $amount: 100%,
  ),
  20%
);

@mixin base {
  transform: scale(1);
  color: $color;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}

@mixin beat {
  color: $colorHi;
  transform: scale(1.1);
  text-shadow: 0 0 14px rgba(255, 255, 255, 0.5);

}

@keyframes beatHeart {
  0% {
    @include base;
  }
  25% {
    @include beat;
  }
  40% {
    @include base;
  }
  60% {
    @include beat;
  }
  100% {
    @include base;
  }
}
